import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AccountInformation from '../../components/account/AccountInformation';
import ChangePassword from '../../components/account/ChangePassword';

function Account({ user }) {
  return (
    <>
      <h1 className="text-center pb-3 fs-2">Account</h1>
      <Row>
        <Col md="6" sm="12">
          <AccountInformation user={user}/>
        </Col>
        <Col md="6" sm="12">
          <ChangePassword/>
        </Col>
      </Row>
    </>
  );
}

export default Account;