import { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function ClipboardFormModal({ show, onClose, onSave, title, clipboard = null }) {
  const [name, setName] = useState('');
  const [validated, setValidated] = useState(false);

  const beforeSave = e => {
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;
    if (!form.checkValidity()) {
      setValidated(true);
      return;
    }

    setValidated(true);
    onSave({ name });
    resetForm();
  }

  const resetForm = () => {
    setName('');
    setValidated(false);
  }

  useEffect(() => {
    if (clipboard) {
      setName(clipboard.name);
    }
  }, [clipboard]);

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form id="clipboard-form" noValidate validated={validated} onSubmit={beforeSave}>
          <Form.Group controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" value={name} required onChange={e => setName(e.target.value)}/>
            <Form.Control.Feedback type="invalid">
              Please enter a name.
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="outline-primary" onClick={onClose}>Close</Button>
        <Button variant="primary" form="clipboard-form" type="submit">Save</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ClipboardFormModal;