import { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';

import http from '../../../services/http';

function AccountInformation({ user }) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [validated, setValidated] = useState(false);
  
  const onSubmit = e => {
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;
    if (!form.checkValidity()) {
      setValidated(true);
      return;
    }

    http.put(`/users/${user.id}`, { firstName, lastName, email })
      .then(() => {
        toast('Account information successful updated', {
          type: toast.TYPE.SUCCESS
        });
        // TODO: update user
      }).catch(err => {
        toast('Email address already registered', {
          type: toast.TYPE.ERROR
        });
      });
  }

  useEffect(() => {
    if (user) {
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setEmail(user.email);
    }
  }, [user]);

  return (
    <Card>
      <Card.Body>
        <Card.Title className="mb-3">Account information</Card.Title>
        <Form noValidate validated={validated} onSubmit={onSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>First name</Form.Label>
            <Form.Control required value={firstName} onChange={e => setFirstName(e.target.value)}/>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Last name</Form.Label>
            <Form.Control required value={lastName} onChange={e => setLastName(e.target.value)}/>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" value={email} required onChange={e => setEmail(e.target.value)}/>
          </Form.Group>
          <Button variant="primary" type="submit">Update</Button>
        </Form>
      </Card.Body>
    </Card>
  );
}

export default AccountInformation;