import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Button from 'react-bootstrap/Button';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import http from '../../services/http';

import './index.scss';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [validated, setValidated] = useState(false);
  const [incorrectCredentials, setIncorrectCredentials] = useState(false);

  const history = useHistory();

  const onLogin = e => {
    e.preventDefault();
    e.stopPropagation();

    setIncorrectCredentials(false);

    const form = e.currentTarget;
    if (!form.checkValidity()) {
      setValidated(true);
      return;
    }

    http.post('/auth/login', { email, password })
      .then(() => {
        setValidated(true);
        history.push('/');
      }).catch(() => {
        setIncorrectCredentials(true);
        setValidated(false);
      });
  }

  return (
    <div className="login-container">
      <Row className="justify-content-center">
        <Col md="6" sm="12">
          <Card>
            <Card.Body className="m-2">
              <Card.Title className="mb-4 fs-4">Login</Card.Title>
              <Form noValidate validated={validated} onSubmit={onLogin}>
                <Form.Group className="mb-3">
                  <FloatingLabel label="Email">
                    <Form.Control type="email" placeholder="Email" required onChange={e => setEmail(e.target.value)} isInvalid={incorrectCredentials}/>
                    <Form.Control.Feedback type="invalid">
                      {!incorrectCredentials && 'Please enter a valid email address'}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group className="mb-3">
                  <FloatingLabel label="Password">
                    <Form.Control type="password" placeholder="Password" required onChange={e => setPassword(e.target.value)} isInvalid={incorrectCredentials}/>
                    <Form.Control.Feedback type="invalid">
                      {incorrectCredentials
                        ? 'Email or password incorrect'
                        : 'Please enter a password'
                      }
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
                <Button variant="primary" type="submit">Login</Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Login;