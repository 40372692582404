import { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Alert from 'react-bootstrap/Alert';
import { BiPlus } from 'react-icons/bi';
import { VscCopy, VscEdit, VscTrash, VscSave } from 'react-icons/vsc';
import { FiCheck, FiShare2 } from 'react-icons/fi';
import { MdContentPaste } from 'react-icons/md';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import http from '../../services/http';
import AddClipboardModal from '../../components/clipboard/AddClipboardModal';
import EditClipboardModal from '../../components/clipboard/EditClipboardModal';
import RemoveClipboardModal from '../../components/clipboard/RemoveClipboardModal';

import './index.scss';
import ShareClipboardModal from '../../components/clipboard/ShareClipboardModal';

function Home({ user }) {
  const [clipboards, setClipboards] = useState(null);
  const [selectedClipboard, setSelectedClipboard] = useState(null);
  const [clipboardItems, setClipboardItems] = useState(null);
  const [copiedItem, setCopiedItem] = useState(null);
  const [pasteInputValue, setPasteInputValue] = useState('');
  const [showAddClipboardModal, setShowAddClipboardModal] = useState(false);
  const [showEditClipboardModal, setShowEditClipboardModal] = useState(false);
  const [showRemoveClipboardModal, setShowRemoveClipboardModal] = useState(false);
  const [showShareClipboardModal, setShowShareClipboardModal] = useState(false);

  const supportsClipboardPaste = navigator.clipboard.readText !== undefined;

  const fetchClipboardItems = () => {
    return http.get(`/clipboards/${selectedClipboard.id}/items`)
      .then(res => {
        setClipboardItems(res.data);
        setCopiedItem(null);
      }).catch(() => {});
  }

  const onKeyPaste = e => {
    if (e.explicitOriginalTarget) {
      if (e.explicitOriginalTarget.id === 'paste-value') {
        return;
      }
    } else if (e.target.id === 'paste-value') {
      return;
    }
    
    onPaste(e.clipboardData.getData('text'));
  }

  const onButtonPaste = () => {
    navigator.clipboard.readText()
      .then(value => onPaste(value));
  }

  const onInputPaste = e => {
    e.preventDefault();
    e.stopPropagation();
    onPaste(pasteInputValue).then(() => {
      setPasteInputValue('');
    });
  }

  const onPaste = value => {
    return http.post(`/clipboards/${selectedClipboard.id}/items`, { content: value })
      .then(() => fetchClipboardItems())
      .catch(() => {});
  }

  useEffect(() => {
    http.get('/clipboards')
      .then(res => {
        const clipboards = res.data;
        setClipboards(clipboards);
        if (clipboards.length > 0) {
          setSelectedClipboard(clipboards[0]);
        }
      }).catch(() => {});
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!selectedClipboard) {
      setClipboardItems(null);
      return;
    }

    fetchClipboardItems().then(() => {
      // Register event listener for paste event
      window.addEventListener('paste', onKeyPaste);
    });
    return () => window.removeEventListener('paste', onKeyPaste);
  // eslint-disable-next-line
  }, [selectedClipboard]);

  const onSelectedClipboardChange = e => setSelectedClipboard(clipboards[e.target.value]);

  const isOwner = clipboard => {
    return user && clipboard.users.find(u => u.userId === user.id)?.isOwner;
  }

  const onClipboardAdded = clipboard => {
    setClipboards([...clipboards, clipboard]);
    setSelectedClipboard(clipboard);
  }

  const onClipboardEdited = clipboard => {
    setClipboards(clipboards.map(c => c.id === clipboard.id ? clipboard : c));
  }

  const onClipboardRemoved = clipboard => {
    const newClipboards = clipboards.filter(c => c.id !== clipboard.id);
    setClipboards(newClipboards);
    setSelectedClipboard(newClipboards.length > 0 ? newClipboards[0] : null);
  }

  const onRemoveClipboardItem = (e, item) => {
    e.stopPropagation();
    http.delete(`/clipboards/${selectedClipboard.id}/items/${item.id}`).then(() => {
      const newItems = clipboardItems.filter(i => i.id !== item.id);
      setClipboardItems(newItems);
    }).catch(() => {});
  }

  if (clipboards === null) {
    return <></>;
  }

  return (
    <>
      {clipboards.length > 0
        ?
          <Row>
            <Col sm="12" md="9" className="order-md-1 order-2">
              {clipboardItems !== null &&
                (clipboardItems.length > 0
                  ?
                    clipboardItems.map(item => 
                      <Row key={item.id} className="pb-2">
                        <Col>
                          <CopyToClipboard text={item.content} onCopy={() => setCopiedItem(item)}>
                            <Card className="clipboard-item">
                              <Card.Body>
                                <Row>
                                  <Col xs>
                                    {item.content}
                                  </Col>
                                  <Col xs="auto">
                                    {copiedItem && copiedItem.id === item.id
                                      ? <span className="text-primary">
                                          Copied to clipboard&nbsp;<FiCheck/>
                                        </span>
                                      : <span className="clipboard-item-copy-text">Click to copy&nbsp;&nbsp;<VscCopy/></span>
                                    }
                                  </Col>
                                  <Col xs="auto">
                                    <VscTrash className="clipboard-item-action-text" onClick={e => onRemoveClipboardItem(e, item)}/>
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          </CopyToClipboard>
                        </Col>
                      </Row>
                    )
                  :
                    <Alert variant="info" className="text-center">This clipboard doesn't have any items yet.</Alert>
                )
              }
            </Col>
            <Col sm="12" md="3" className="order-md-2 order-1 mb-3">
              <Row>
                <Col>
                  <InputGroup>
                    <Form.Select className="pointer" aria-label="Select clipboard" onChange={onSelectedClipboardChange}>
                      {clipboards.map((clipboard, index) => 
                        <option key={clipboard.id} value={index}>{clipboard.name + (!isOwner(clipboard) ? ' (shared)' : '')}</option>
                      )}
                    </Form.Select>
                    <Button variant="primary" className="btn-icon" onClick={() => setShowAddClipboardModal(true)}>
                      <BiPlus/>
                    </Button>
                  </InputGroup>
                </Col>
              </Row>
              {selectedClipboard && 
                <>
                  {isOwner(selectedClipboard) &&
                    <>
                      <Row className="pt-2 gx-2">
                        <Col>
                          <Button variant="outline-primary" className="btn-icon w-100" onClick={() => setShowEditClipboardModal(true)}>
                            <VscEdit/>&nbsp;Edit
                          </Button>
                        </Col>
                        <Col>
                          <Button variant="outline-primary" className="btn-icon w-100" onClick={() => setShowRemoveClipboardModal(true)}>
                            <VscTrash/>&nbsp;Delete
                          </Button>
                        </Col>
                      </Row>
                      <Row className="pt-2">
                        <Col>
                          <Button variant="outline-primary" className="btn-icon w-100" onClick={() => setShowShareClipboardModal(true)}>
                            <FiShare2/>&nbsp;Share
                          </Button>
                        </Col>
                      </Row>
                    </>
                  }
                  {supportsClipboardPaste
                    ?
                      <Row className="pt-2">
                        <Col>
                          <Button variant="primary" size="lg" className="btn-icon w-100" onClick={onButtonPaste}>
                            <MdContentPaste/>&nbsp;Paste
                          </Button>
                        </Col>
                      </Row>
                    :
                      <Row className="pt-3">
                        <Col>
                          <Form noValidate onSubmit={onInputPaste}>
                            <Form.Group controlId="paste-value">
                              <Form.Control type="text" placeholder="Paste here" value={pasteInputValue} required onChange={e => setPasteInputValue(e.target.value)}/>
                            </Form.Group>
                            <Button variant="primary" type="submit" className="btn-icon w-100 mt-2" disabled={!pasteInputValue}>
                              <VscSave/>&nbsp;Save
                            </Button>
                          </Form>
                        </Col>
                      </Row>
                  }
                </>
              }
            </Col>
          </Row>
        :
          <>
            <Row className="pt-3">
              <Col>
                <p className="text-center">You don't have any clipboards. Click on the button below to add one.</p>
              </Col>
            </Row>
            <Row className="pt-1">
              <Col className="text-center">
                <Button variant="primary" onClick={() => setShowAddClipboardModal(true)}>
                  <BiPlus/>&nbsp;Add clipboard
                </Button>
              </Col>
            </Row>
          </>
      }
      <AddClipboardModal show={showAddClipboardModal} onClose={() => setShowAddClipboardModal(false)} onAddSuccess={onClipboardAdded}/>
      <EditClipboardModal show={showEditClipboardModal} onClose={() => setShowEditClipboardModal(false)} onEditSuccess={onClipboardEdited} clipboard={selectedClipboard}/>
      <RemoveClipboardModal show={showRemoveClipboardModal} onClose={() => setShowRemoveClipboardModal(false)} onRemoveSuccess={onClipboardRemoved} clipboard={selectedClipboard}/>
      <ShareClipboardModal show={showShareClipboardModal} onClose={() => setShowShareClipboardModal(false)} user={user} clipboard={selectedClipboard}/>
    </>
  );
}

export default Home;