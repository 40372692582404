import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import http from '../../../services/http';

function RemoveClipboardModal({ show, onClose, onRemoveSuccess, clipboard }) {
  const onRemoveClipboard = () => {
    http.delete(`/clipboards/${clipboard.id}`)
      .then(() => {
        onRemoveSuccess(clipboard);
        onClose();
      });
  }

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Delete {clipboard?.name}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        Are you sure you want to delete the clipboard '{clipboard?.name}'?
      </Modal.Body>

      <Modal.Footer>
        <Button variant="outline-primary" onClick={onClose}>Close</Button>
        <Button variant="primary" onClick={onRemoveClipboard}>Delete</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default RemoveClipboardModal;