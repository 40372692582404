import axios from 'axios';

import config from '../config';

const axiosInst = axios.create({
  baseURL: config.apiUrl
});

let onLogout = null;

function init(_onLogout) {
  onLogout = _onLogout;
}

axiosInst.interceptors.response.use(null, error => {
  if (error.response && error.response.status === 403) {
    onLogout();
  }
  return Promise.reject(error);
});

export default axiosInst;

export { init, axiosInst as axios }