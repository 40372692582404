import { useEffect, useState } from 'react';
import {
  Switch,
  Route,
  useHistory,
  useLocation
} from 'react-router-dom';
import Container from 'react-bootstrap/Container';

import NavHeader from './components/NavHeader';
import Home from './pages/Home';
import Login from './pages/Login';
import { init as initHttp, axios } from './services/http';
import Account from './pages/Account';
import { ToastContainer } from 'react-toastify';

function App() {
  const [user, setUser] = useState(null);

  const history = useHistory();
  const location = useLocation();

  const onLogout = () => {
    axios.post('/auth/logout')
      .finally(() => {
        setUser(null);
        history.push('/login');
      });
  }

  useEffect(() => {
    initHttp(() => {
      setUser(null);
      history.push('/login');
    });
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!user && location.pathname !== '/login') {
      axios.get('/auth/me')
        .then(res => {
          setUser(res.data);
        }).catch(() => {});
    }
  // eslint-disable-next-line
  }, [location])

  return (
    <>
      <NavHeader user={user} onLogout={onLogout}/>
      <Container className="main-container">
        <Switch>
          <Route exact path="/">
            <Home user={user}/>
          </Route>
          <Route exact path="/account">
            <Account user={user}/>
          </Route>
          <Route exact path="/login">
            <Login/>
          </Route>
        </Switch>
      </Container>
      <ToastContainer/>
    </>
  );
}

export default App;
