import ClipboardFormModal from '../ClipboardFormModal';
import http from '../../../services/http';

function AddClipboardModal({ show, onClose, onAddSuccess }) {
  const onAddClipboard = clipboard => {
    http.post('/clipboards', clipboard)
      .then(res => {
        onAddSuccess(res.data);
        onClose();
      }).catch(() => {});
  }

  return (
    <ClipboardFormModal title="Add clipboard" show={show} onClose={onClose} onSave={onAddClipboard}/>
  );
}

export default AddClipboardModal;