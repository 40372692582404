import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import http from '../../../services/http';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

function ShareClipboardModal({ show, onClose, user, clipboard }) {
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  useEffect(() => {
    if (show) {
      http.get('/display-users')
        .then(res => {
          setUsers(res.data);
        });
    }
  }, [show]);

  useEffect(() => {
    setSelectedUsers(clipboard ? [...clipboard.users.filter(u => u.userId !== user.id).map(u => u.userId)] : []);
  }, [clipboard, user])

  const onSelectUser = (userId, checked) => {
    if (checked) {
      setSelectedUsers([...selectedUsers, userId]);
    } else {
      const index = selectedUsers.indexOf(userId);
      setSelectedUsers([...selectedUsers.slice(0, index), ...selectedUsers.slice(index + 1)]);
    }
  }

  const onShare = () => {
    http.post(`/clipboards/${clipboard.id}/share`, {
      userIds: selectedUsers
    }).then(() => {
      toast('Successfully updated share status', {
        type: toast.TYPE.SUCCESS
      });
      onClose();
    }).catch(() => {});
  }

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Share {clipboard?.name}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>Share clipboard "{clipboard?.name}" with:</p>
        <Form>
          <Form.Group>
            {users.filter(u => u.id !== user?.id && clipboard?.users.some(u2 => u2.userId !== u.id))
              .map(u =>
                <Form.Check type="switch" label={`${u.firstName} ${u.lastName}`} key={u.id} checked={selectedUsers.includes(u.id)} onChange={e => onSelectUser(u.id, e.target.checked)}/>
              )
            }
          </Form.Group>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="outline-primary" onClick={onClose}>Close</Button>
        <Button variant="primary" onClick={onShare}>Confirm</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ShareClipboardModal;