import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';
import { LinkContainer } from 'react-router-bootstrap';
import { VscAccount } from 'react-icons/vsc';

import './index.scss';

function NavHeader({ user, onLogout }) {
  return (
    <Navbar bg="primary">
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand>
            Clipboard
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="main-nav"/>
        <Navbar.Collapse id="main-nav">
          <Nav className="ms-auto">
            {user &&
              <Dropdown className="menu-dropdown">
                <Dropdown.Toggle>
                  <VscAccount style={{fontSize: '2rem'}}/>
                </Dropdown.Toggle>
                <Dropdown.Menu align='end'>
                  <Dropdown.Item className="user-name" disabled>{user.firstName} {user.lastName}</Dropdown.Item>
                  <Dropdown.Divider/>
                  <LinkContainer to="/" activeClassName="">
                    <Dropdown.Item>My clipboards</Dropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/account" activeClassName="">
                    <Dropdown.Item>Account</Dropdown.Item>
                  </LinkContainer>
                  <Dropdown.Item onClick={onLogout}>Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            }
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavHeader;