import ClipboardFormModal from '../ClipboardFormModal';
import http from '../../../services/http';

function EditClipboardModal({ show, onClose, onEditSuccess, clipboard }) {
  const onEditClipboard = _clipboard => {
    http.put(`/clipboards/${clipboard.id}`, _clipboard)
      .then(res => {
        onEditSuccess(res.data);
        onClose();
      });
  }

  return (
    <ClipboardFormModal title="Edit clipboard" show={show} onClose={onClose} onSave={onEditClipboard} clipboard={clipboard}/>
  );
}

export default EditClipboardModal;