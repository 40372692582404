import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';

import http from '../../../services/http';

function ChangePassword() {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [validated, setValidated] = useState(false)

  const onSubmit = e => {
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;
    if (!form.checkValidity()) {
      setValidated(true);
      return;
    }

    if (newPassword !== repeatPassword) {
      toast("Passwords don't match", {
        type: toast.TYPE.ERROR
      });
      return;
    }

    http.post('/auth/change-password', { oldPassword, newPassword })
      .then(() => {
        toast('Password successful changed', {
          type: toast.TYPE.SUCCESS
        });
        setOldPassword('');
        setNewPassword('');
        setRepeatPassword('');
      }).catch(() => {
        toast('Old password incorrect', {
          type: toast.TYPE.ERROR
        });
      })
  }

  return (
    <Card>
      <Card.Body>
        <Card.Title className="mb-3">Change password</Card.Title>
        <Form noValidate validated={validated} onSubmit={onSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Old password</Form.Label>
            <Form.Control type="password" required value={oldPassword} onChange={e => setOldPassword(e.target.value)}/>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>New password</Form.Label>
            <Form.Control type="password" required value={newPassword} onChange={e => setNewPassword(e.target.value)}/>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Repeat new password</Form.Label>
            <Form.Control type="password" required value={repeatPassword} onChange={e => setRepeatPassword(e.target.value)}/>
          </Form.Group>
          <Button variant="primary" type="submit">Change</Button>
        </Form>
      </Card.Body>
    </Card>
  );
}

export default ChangePassword;